<template>
    <layout @search="getPeriodicalList" class="periodical">
        <!-- 面包屑 -->
        <!-- <div class="period-box"> -->
            <!-- <div class="container bread-list mt15 flex x-left flex-wrap">
                <div class="color999">您所在的位置：</div>
                <a-breadcrumb>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{path:'/'}">首页</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{path:'/journal-article'}">期刊论文</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{path:''}">期刊杂志</router-link>
                    </a-breadcrumb-item>
                </a-breadcrumb>
            </div> -->
            <!-- <div class="container filter-wrapper" v-if="tabList.length">
                <filter-tab title="学科" :list="tabList" :isShowMore="true" @clickItem="handleTapSubject"></filter-tab>
            </div>
        </div> -->

        <!-- 筛选条件 -->
        <!-- <div v-if="subjectIndex != -1 " class="filter-condition active">
            <div class="container flex y-center x-left">
                <div class="search-text">搜索条件：</div>
                <a-breadcrumb class="search-breadcrumb">
                    <a-breadcrumb-item v-if="subjectIndex != -1">{{ subjectList[subjectIndex].operate_name }}
                    </a-breadcrumb-item>
                </a-breadcrumb>
                <a-button class="clear-btn" @click="handleClearEvent"
                    style="margin-left:20px;" size="small">清除</a-button>
            </div>
        </div>
        <div v-else class="filter-condition"></div> -->
        
        <div class="container perio-list flex mt30">
            <div class="meeting-report mh">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="flex flex-wrap" v-if="periodicalList.length">
                        <div class="flex jounal-items" v-for="(item, index) in periodicalList" :key="index">
                            <journal-item path="/periodical-detail" width="160px" height="200px" type="2" :journalItem="item"></journal-item>
                        </div>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!periodicalList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="periodicalCount > 0">
                    <a-pagination :total="periodicalCount" v-model:current="periodicalParams.page" :page-size="periodicalParams.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="meeting-right">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index';
import layoutRight from '@/components/layout/layout-right';
import journalItem from '../../components/journal-item.vue';
import filterTab from '@/components/filter-tab.vue';
import { jsonFormat } from '@/utils/jsonFormat.js';
import storage from 'store'
export default {
    name: 'PeriodicalList',
    components: {
        layout,
        layoutRight,
        filterTab,
        journalItem
    },
    data() {
        return {
            tabList: [],
            periodicalParams: {
                page: 1,
                limit: 10,
            },
            periodicalCount: 0,
            periodicalList: [],
            layoutRightConfig: {
                // 本月会议
                meeting: {
                    show: true, // 是否显示
                    title: '本月会议', // 标题
                    methods: 'get',
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 3,
                        is_now_year_meeting: 1,
                        subject_ids: storage.get('subjectIds') || ''
                    },   // 参数条件
                },
                // 推荐直播
                live: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '推荐直播', // 标题
                    data: {
                        page: 1,
                        limit: 3
                    },   // 参数条件
                },
                // 推荐视频
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '最新回放', // 标题
                    data: {
                        page: 1,
                        PageSize: 4
                    },   // 参数条件
                },
            },
            loading: true,
        }
    },
    created() {
        this.getSubject()
        // this.getPeriodicalList();
    },
    // 缓存页面二次进入的钩子函数  动态参数更新时开启
    activated() {
        this.getPeriodicalList();
    },
    methods: {
        getSubject() {
            this.$store.dispatch('getSubjectList').then(res => {
                let subjectObj = {
                    name: 'operate_name',
                }
                this.tabList = jsonFormat(res.data, subjectObj)
            })
        },
        // 期刊杂志列表
        getPeriodicalList() {
            this.loading = true
            // this.periodicalParams.page = 1
            let data = { ...this.periodicalParams }
            let { search_name } = this.$route.query;
            search_name && (data['cn_name'] = search_name);
            this.request.post('PeriodicalList', data).then(res => {
                let periObj = {
                    desc: 'en_name',
                }
                this.periodicalCount = parseInt(res.data.count)
                this.periodicalList = jsonFormat(res.data.list, periObj)
                this.loading = false
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.periodicalParams.page = page
            this.getPeriodicalList()
        },
        handleTapSubject(index, id) {
            this.periodicalParams.page = 1
            if (id == -1) {
                delete this.periodicalParams.operate_id
            } else {
                this.periodicalParams.operate_id = id
            }
            this.getPeriodicalList()
        }
    }
}
</script>
<style lang="less" scoped>
.periodical {
    .filter-box:not(:last-child) {
        border-bottom: 0;
    }
    .filter-condition {
        overflow: hidden;
        height: 8px;
        background: f2f4fa;
        &.active {
            height: 50px;
            line-height: 50px;
        }
    }

    .meeting-report {
        width: calc(66.66% - 30px);

        .mh {
            min-height: 500px;
        }
    }

    .meeting-right {
        width: 33.33%;
    }

    .jounal-items {
        width: 365px;
        cursor: pointer;
    }
}
@media screen and (max-width:768px){
    .periodical{
        .period-box{
            padding: 0 10px;
            .filter-wrapper{
                margin-left: -14px;
                position: fixed;
                margin-top: 30px;
                z-index: 999;
                background: #fff;
            }
            .bread-list{
                position: fixed;
                background: #fff;
                z-index: 999;
                padding-top: 10px;
                margin-top: 0;
            }
        }
        .perio-list{
            display: block;
            margin-top: 10px;
            width: 100%;
            .meeting-report{
                width: 100%;
                padding: 0 10px;
                // margin-top: 80px;
                .jounal-items{
                    width: 49%;
                }
            }
            .meeting-right{
                width: 100%;
                padding: 0 10px;
                margin-top: 60px;
            }
        }
    }
}
@media screen and (max-width:650px){
    .periodical{
        .perio-list{
            .meeting-report{
                .jounal-items{
                    width: 100%;
                }
            }
        }
    }
}
</style>
